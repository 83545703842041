var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("baidu-map", {
        staticClass: "map-page",
        attrs: {
          center: _vm.center,
          zoom: _vm.zoom,
          "continuous-zoom": true,
          "scroll-wheel-zoom": true,
        },
        on: { ready: _vm.initMap },
      }),
      _vm.playerVisible
        ? _c(
            "div",
            { staticClass: "track-container" },
            [
              _vm.isParkingArea
                ? _c(
                    "div",
                    { staticClass: "park" },
                    [
                      _c("span", [_vm._v("可停车区域")]),
                      _c("a-switch", {
                        attrs: {
                          "checked-children": "开",
                          "un-checked-children": "关",
                        },
                        model: {
                          value: _vm.isShowFence,
                          callback: function ($$v) {
                            _vm.isShowFence = $$v
                          },
                          expression: "isShowFence",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.playerVisible
                ? _c("Player", {
                    staticStyle: { width: "40%" },
                    attrs: {
                      objectInfo: _vm.objectInfo,
                      moduleKey: _vm.moduleKey,
                      currentTrackPoints: _vm.currentTrackPoints,
                      mapObj: _vm.mapObj,
                    },
                    on: {
                      mapTrackPlay: _vm.handleMapTrackPlay,
                      mapSetTrackPlaySpeed: _vm.handleMapSetTrackPlaySpeed,
                      mapTrackPlayJumpTo: _vm.handleMapTrackPlayJumpTo,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }