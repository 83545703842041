<template>
  <div class="container">
    <!-- 轨迹地图区 -->
    <baidu-map class="map-page" :center="center" :zoom="zoom" @ready="initMap" :continuous-zoom="true" :scroll-wheel-zoom="true"> </baidu-map>

    <div class="track-container" v-if="playerVisible">
      <div v-if="isParkingArea" class="park"><span>可停车区域</span><a-switch v-model="isShowFence" checked-children="开" un-checked-children="关" /></div>
      <!-- 轨迹播放操作区 -->
      <Player
        v-if="playerVisible"
        :objectInfo="objectInfo"
        :moduleKey="moduleKey"
        :currentTrackPoints="currentTrackPoints"
        @mapTrackPlay="handleMapTrackPlay"
        @mapSetTrackPlaySpeed="handleMapSetTrackPlaySpeed"
        @mapTrackPlayJumpTo="handleMapTrackPlayJumpTo"
        :mapObj="mapObj"
        style="width: 40%"
      />
    </div>
  </div>
</template>
<script>
import TrackPlayerHelper from '../mappage/TrackPlayHelper'
import Player from './modules/player.vue'
import { loadScript } from '@/utils/util'
import { DrawCircle, DrawPolygon, DrawPolyline, DrawRectangle } from '@/views/monitor/monitorComponents/mappage/MapOverlayHelper'
export default {
  mixins: [TrackPlayerHelper],
  components: { Player },
  props: {
    params: {
      type: Object,
      default: () => {}
    },
    // 轨迹数据
    trackData: {
      type: Array,
      default: () => {}
    },
    // 显示轨迹播放器
    showPlayer: {
      type: Boolean,
      default: false
    },
    // 显示轨迹信息
    showTrackInfo: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      center: {
        lng: 104.114129,
        lat: 37.550339
      },
      zoom: 6,
      // 轨迹分组数据
      currentTrackGroupArray: [],
      playerVisible: this.showPlayer,
      currentTrackPoints: [],
      objectInfo: null,
      moduleKey: '',
      mapObj: {},
      // 是否为可停车区域
      isParkingArea: false,
      // 是否显示围栏
      isShowFence: true,
      // 围栏基础样式
      fenceStyleOptions: {
        strokeColor: '#a6a6fc', // 边线颜色。
        fillColor: '#a6a6fc', // 填充颜色。当参数为空时，圆形将没有填充效果。
        strokeWeight: 3, // 边线的宽度，以像素为单位。
        strokeOpacity: 0.5, // 边线透明度，取值范围0 - 1。
        fillOpacity: 0.5, // 填充的透明度，取值范围0 - 1。
        strokeStyle: '#a6a6fc' // 边线的样式，solid或dashed。
      },
      // 轨迹坐标
      trackPoints: [],
      // 围栏坐标
      fencePoints: [],
      // 围栏overlay
      fenceOverlays: []
    }
  },
  watch: {
    params(newVal, oldVal) {
      if (!newVal) {
        // 清除之前的轨迹
      } else {
        this.initTrack(newVal)
      }
    },
    showPlayer(newVal, oldVal) {
      this.playerVisible = newVal
    },
    trackData(newVal, oldVal) {
      this.currentTrackPoints = newVal
      if (newVal && newVal.length > 0) {
        this.doTrack(newVal)
      }
    },
    // 是否显示围栏控制
    isShowFence(newVal, oldVal) {
      // 1.控制是否显示围栏
      if (!newVal) {
        this.fenceOverlays.forEach((p) => {
          this.trackMap.removeOverlay(p)
        })
        this.fenceOverlays = []
        // 重置轨迹地图视野
        this.trackMap.setViewport(this.trackPoints)
      } else if (newVal) {
        this.drawFence(this.params.fenceData)
        // 重置轨迹＋围栏视野
        this.trackMap.setViewport([...this.trackPoints, ...this.fencePoints])
      }
    }
  },
  created() {
    console.log('trackPage-created')
    this.initTrack(this.params)
  },
  methods: {
    // 绘制围栏
    drawFence(data) {
      if (!data) {
        return
      }
      // 先移除之前overlay数据
      const warnType = this.params.warnType
      console.log('warnType', warnType)
      if (warnType === 1) {
        this.fenceStyleOptions.fillColor = 'blue'
      } else if (warnType === 4) {
        this.fenceStyleOptions.fillColor = 'green'
      } else if (warnType === 3) {
        this.fenceStyleOptions.fillColor = 'red'
      }
      const map = this.trackMap
      const BMap = this.trackBMap
      this.fencePoints = []
      data.forEach((p) => {
        if (p.geomText) {
          const points = p.geomText.split(',')
          let overlay = null
          if (p.geomType === 1) {
            overlay = DrawCircle(points, BMap, this.fenceStyleOptions)
            map.addOverlay(overlay)
            this.fencePoints.push(new BMap.Point(points[0], points[1]))
          } else if (p.geomType === 2) {
            overlay = DrawRectangle(points, BMap, this.fenceStyleOptions)
            map.addOverlay(overlay)
            this.fencePoints.push(new BMap.Point(points[0], points[1]))
            this.fencePoints.push(new BMap.Point(points[2], points[3]))
          } else if (p.geomType === 3) {
            overlay = DrawPolygon(points, BMap, this.fenceStyleOptions)
            map.addOverlay(overlay)
            const n = Math.floor(points.length / 2)
            for (let k = 0; k < n; k++) {
              this.fencePoints.push(new BMap.Point(points[2 * k], points[2 * k + 1]))
            }
          } else if (p.geomType === 4) {
            overlay = DrawPolyline(points, BMap, this.fenceStyleOptions)
            map.addOverlay(overlay)
            this.fencePoints.push(new BMap.Point(points[0], points[1]))
            this.fencePoints.push(new BMap.Point(points[2], points[3]))
          }
          if (overlay) {
            this.fenceOverlays.push(overlay)
          }
        }
      })
    },
    initTrack(data) {
      console.log('initTrack')
      if (!data) {
        return
      }
      this.moduleKey = data.moduleKey
      this.objectInfo = data.objectInfo
      if (this.trackMap) {
        // 先清除
        // const ov = this.trackMap.getOverlays()
        // ov.forEach((p) => {
        //   if (!p.Wa) {
        //     this.trackMap.removeOverlay(p)
        //   }
        // })
        const fenceData = this.params.fenceData
        if (fenceData) {
          // 绘制围栏
          console.log('绘制围栏', fenceData)
          this.isParkingArea = true
          // 绘制围栏
          this.drawFence(fenceData)
          //  如果没有轨迹数据
          if (!data.trackData || data.trackData.length === 0) {
            // 如果没有轨迹数据，则直接重置地图视野
            this.trackMap.setViewport(this.fencePoints)
          }
        }
        setTimeout(() => {
          if (data.moduleKey === 'traditionalVehicle') {
            // 传统车辆
            this.deleteLushuMarker()
            if (data.trackData && data.trackData.length > 0) {
              this.initHistoryTrackPlay2(data.trackData, () => {
                this.currentTrackGroupArray = [{ normal: data.trackData.map((p) => new this.trackBMap.Point(p.lng, p.lat)) }]
                this.currentTrackPoints = data.trackData
                this.drawTrackPolyline()
                // 围栏+轨迹点
                this.trackPoints = data.trackData.map((p) => new this.trackBMap.Point(p.lng, p.lat))
                if (this.fencePoints && this.fencePoints.length > 0) {
                  // 轨迹点位＋围栏点位
                  this.trackMap.setViewport([...this.trackPoints, ...this.fencePoints])
                } else {
                  // 仅轨迹点位
                  this.trackMap.setViewport(this.trackPoints)
                }
              })
            }
          }
        }, 1000)
      } else {
        setTimeout(() => {
          this.initTrack(data)
        }, 500)
      }
    },
    doTrack(data) {
      console.log('绘制轨迹线')
      // 绘制轨迹线
      setTimeout(() => {
        this.deleteLushuMarker()
        this.initHistoryTrackPlay(data, () => {
          this.currentTrackGroupArray = this.parseTrackData(data)
          this.drawTrackPolyline()
        })
      }, 500)
    },
    initMap(MapObj) {
      this.trackBMap = MapObj.BMap
      this.trackMap = MapObj.map
      this.zoom = 10
      const myGeo = new this.trackBMap.Geocoder()
      // 返回对象实时地图实例对象
      this.mapObj = {
        mapGeo: myGeo,
        BMap: this.trackBMap
      }
      if (!window.BMapLib.GeoUtils) {
        loadScript('//api.map.baidu.com/library/GeoUtils/1.2/src/GeoUtils_min.js')
      }
    },
    setViewport(data) {
      if (data && data.length > 0) {
        const points = data.map((p) => new this.trackBMap.Point(p.lng, p.lat))
        this.trackMap && this.trackMap.setViewport(points)
      }
    },
    handleMapTrackPlay(status, oldStatus) {
      this.mapTrackPlay(status, oldStatus)
    },
    handleMapSetTrackPlaySpeed(val) {
      this.mapSetTrackPlaySpeed(val)
    },
    handleMapTrackPlayJumpTo(val, status) {
      this.mapTrackPlayJumpTo(val, status)
    }
  },
  activated() {
    // 页面激活
    console.log('trackpage-activated', this.params)
    this.playerVisible = this.params.showPlayer
    this.currentTrackPoints = this.params.trackData
    this.moduleKey = this.params.params.type
    this.doTrack(this.params.trackData)
  },
  deactivated() {
    console.log('trackpage-deactivated', this.params)
  }
}
</script>
<style lang="less" scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  .track-container {
    position: relative;
    flex: 0 0 130px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    .park {
      position: absolute;
      right: 10px;
      top: 0px;
      > span {
        margin-right: 10px;
      }
    }
  }
  .map-page {
    height: 100%;
    flex: 1;
  }
}
</style>
